import { ReactNode } from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import Markdown from 'markdown-to-jsx';

import Layout from '../../components/Layout/Layout';
import Scroller from '../../components/Scroller/Scroller';
import Builder from './Builder';

import styles from '../../styles/pages/osm.module.scss';

const OnSiteMessaging = ({ children }: { children?: ReactNode }) => {
  const { t, originalPath, i18n } = useI18next();

  return (
    <Layout
      t={t}
      originalPath={originalPath}
      i18n={i18n}
      SEOProps={{ title: 'On-site Messaging' }}
    >
      <Scroller className={styles.main}>
        <h1>{t(`main-nav-on-site-messaging`)}</h1>
        <div className={styles.desc}>
          <Markdown options={{ forceBlock: true }}>{t('osm-desc')}</Markdown>
        </div>
        <h2 className={styles.titleBuilder}>
          {t(`osm-builder-title-builder`)}
        </h2>
        <Builder />
        {children}
      </Scroller>
    </Layout>
  );
};

export default OnSiteMessaging;
