import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { useI18next } from 'gatsby-plugin-react-i18next';
import useHash from '../../hooks/use-hash';
import raw from 'raw.macro';
import Markdown from 'markdown-to-jsx';

import { getPrettifiedDOMSting } from '../../utils';
import {
  builderTypes,
  builderOptionByType, // option configs
  defaultBuilderOptions, // parsed default values for state
  SelectBuilderOption,
  DefaultBuilderOptionByType,
  Condition,
} from './builder-options';

import styles from './Builder.module.scss';
import CodeBox from '../CodeBox/CodeBox';

import iconRecommendation from '../../assets/icons/icon-recommendation.svg';
import useIsSSR from '../../hooks/use-is-ssr';

declare global {
  interface Window {
    smartpay: any;
  }
}

const Preview = ({
  options,
  category,
  forCodeBox,
}: {
  options: DefaultBuilderOptionByType;
  category: string;
  forCodeBox?: boolean;
}) => {
  const option = options[category];
  const osmRef = useRef<HTMLDivElement>(null);
  const parentRef = useRef<HTMLElement | null>(null);

  const { t } = useI18next();

  useLayoutEffect(() => {
    if (osmRef.current) {
      parentRef.current = osmRef.current.parentNode as HTMLElement;
    }

    window?.smartpay?.messaging?.render();

    return () => {
      (
        document.querySelector(
          '.smartpay-osm-product-modal header button'
        ) as HTMLButtonElement
      )?.click();

      if (category === 'corner') {
        (
          document.querySelector(
            '.smartpay-osm-corner header button'
          ) as HTMLButtonElement
        )?.click();

        if (osmRef.current && parentRef.current) {
          parentRef.current.appendChild(osmRef.current);
        }
      }
    };
  }, []);

  switch (category) {
    case 'hosted-faq':
      return (
        <div
          ref={osmRef}
          className={`smartpay-osm-${category}`}
          data-prop-brand-name={option['brand-name']}
        />
      );
    case 'payment-method':
      return (
        <div
          ref={osmRef}
          className={`smartpay-osm-${category}`}
          data-prop-theme={option.theme}
          data-prop-logo-theme={option['logo-theme']}
          data-prop-amount={option.amount}
          data-prop-quantity={option.quantity}
          {...(option['max-amount'] && {
            'data-prop-max-amount': option['max-amount'],
          })}
          {...(option['max-width'] && {
            'data-prop-max-width': option['max-width'],
          })}
          {...(option['shopify-page'] && {
            'data-prop-shopify-page': option['shopify-page'],
          })}
          {...(option.coupon && {
            'data-prop-coupon': option.coupon,
            'data-prop-coupon-discount-type': option['coupon-discount-type'],
          })}
          {...(option.coupon &&
            option['coupon-discount-type'] === 'amount' && {
              'data-prop-coupon-discount-amount':
                option['coupon-discount-amount'],
            })}
          {...(option.coupon &&
            option['coupon-discount-type'] === 'percent' && {
              'data-prop-coupon-discount-percent':
                option['coupon-discount-percent'],
            })}
          {...(option.coupon &&
            option['coupon-expires-at'] && {
              'data-prop-coupon-expires-at': option['coupon-expires-at'],
            })}
          {...(option.coupon &&
            option['coupon-max-count'] && {
              'data-prop-coupon-max-count': option['coupon-max-count'],
            })}
          {...(option.coupon &&
            option['coupon-minimum-amount'] && {
              'data-prop-coupon-minimum-amount':
                option['coupon-minimum-amount'],
            })}
        />
      );
    case 'corner':
      const osm = (
        <div
          ref={osmRef}
          className={`smartpay-osm-${category}`}
          data-prop-theme={option.theme}
          data-prop-position={option.position}
          {...(option['with-button'] && {
            'data-prop-with-button': option['with-button'],
          })}
          {...(option.coupon && {
            'data-prop-coupon': option.coupon,
            'data-prop-coupon-discount-type': option['coupon-discount-type'],
          })}
          {...(option.coupon &&
            option['coupon-discount-type'] === 'amount' && {
              'data-prop-coupon-discount-amount':
                option['coupon-discount-amount'],
            })}
          {...(option.coupon &&
            option['coupon-discount-type'] === 'percent' && {
              'data-prop-coupon-discount-percent':
                option['coupon-discount-percent'],
            })}
          {...(option.coupon &&
            option['coupon-minimum-amount'] && {
              'data-prop-coupon-minimum-amount':
                option['coupon-minimum-amount'],
            })}
        />
      );

      if (forCodeBox) {
        return osm;
      }

      return (
        <div>
          <span>{t('check-the-corner-of-the-page')}</span>
          {osm}
        </div>
      );
    case 'banner':
      return (
        <div
          className={`smartpay-osm-${category}`}
          data-prop-theme={option.theme}
          {...(option.link && {
            'data-prop-link': option.link,
          })}
        />
      );
    case 'strip':
      return (
        <div
          ref={osmRef}
          className={`smartpay-osm-${category}`}
          data-prop-theme={option.theme}
          {...(option.link && {
            'data-prop-link': option.link,
          })}
        />
      );
    case 'coupon':
      return (
        <div
          ref={osmRef}
          className={`smartpay-osm-${category}`}
          data-prop-type={option.type}
          data-prop-theme={option.theme}
          data-prop-coupon={option.coupon}
          data-prop-discount-type={option['discount-type']}
          {...(option['discount-type'] === 'amount' && {
            'data-prop-discount-amount': option['discount-amount'],
          })}
          {...(option['discount-type'] === 'percent' && {
            'data-prop-discount-percent': option['discount-percent'],
          })}
          {...(option['expires-at'] && {
            'data-prop-expires-at': option['expires-at'],
          })}
          {...(option['minimum-amount'] && {
            'data-prop-minimum-amount': option['minimum-amount'],
          })}
          {...(option.link && {
            'data-prop-link': option.link,
          })}
        />
      );
    case 'product':
    default:
      return (
        <div
          ref={osmRef}
          className={`smartpay-osm-${category}`}
          data-prop-type={option.type}
          {...(option.type === 'block' && {
            'data-prop-theme': option.theme,
            'data-prop-logo-theme': option['logo-theme'],
          })}
          data-prop-amount={option.amount}
          data-prop-quantity={option.quantity}
          {...(option['max-amount'] && {
            'data-prop-max-amount': option['max-amount'],
          })}
          {...(option['max-width'] && {
            'data-prop-max-width': option['max-width'],
          })}
          {...(option['shopify-page'] && {
            'data-prop-shopify-page': option['shopify-page'],
          })}
          {...(option.coupon && {
            'data-prop-coupon': option.coupon,
            'data-prop-coupon-discount-type': option['coupon-discount-type'],
          })}
          {...(option.coupon &&
            option['coupon-discount-type'] === 'amount' && {
              'data-prop-coupon-discount-amount':
                option['coupon-discount-amount'],
            })}
          {...(option.coupon &&
            option['coupon-discount-type'] === 'percent' && {
              'data-prop-coupon-discount-percent':
                option['coupon-discount-percent'],
            })}
          {...(option.coupon &&
            option['coupon-expires-at'] && {
              'data-prop-coupon-expires-at': option['coupon-expires-at'],
            })}
          {...(option.coupon &&
            option['coupon-max-count'] && {
              'data-prop-coupon-max-count': option['coupon-max-count'],
            })}
          {...(option.coupon &&
            option['coupon-minimum-amount'] && {
              'data-prop-coupon-minimum-amount':
                option['coupon-minimum-amount'],
            })}
        />
      );
  }
};

const Builder = () => {
  const [hash, setHash] = useHash();
  const [category, setCategory] = useState('product');
  const [options, setOptions] = useState(defaultBuilderOptions);
  const isSSR = useIsSSR();

  useEffect(() => {
    setCategory(
      builderTypes.includes(hash.slice(1)) ? hash.slice(1) : 'product'
    );
  }, [hash]);

  const { t } = useI18next();

  // This extra level of component is used to always create a new DOMNode when state changed.
  const PreviewBlock = () => {
    return <Preview options={options} category={category} />;
  };

  return (
    <main>
      <section className={styles.builder}>
        <form className={styles.form}>
          <h3>{t(`osm-builder-label-category`)}</h3>
          <fieldset className={styles.category}>
            <div className="select-box">
              <select
                value={category}
                onChange={(event) => {
                  const value = event.currentTarget.value;

                  setHash(value);
                  setCategory(value);
                }}
              >
                {builderTypes.map((option: string, idx: number) => (
                  <option key={idx} value={option}>
                    {t(`osm-builder-label-category-${option}`)}
                  </option>
                ))}
              </select>
            </div>
          </fieldset>
        </form>
        <section className={styles.output}>
          <h3 className={styles.titleCategory}>
            {t(
              `osm-builder-label-category-${builderOptionByType[category].id}`
            )}
          </h3>
          <Markdown options={{ forceBlock: true }}>
            {t(`osm-builder-desc-${category}`)}
          </Markdown>
          <div className={styles.tip}>
            <img
              src={iconRecommendation}
              alt=""
              width="56"
              height="56"
              className={styles.icon}
            />
            <Markdown options={{ forceBlock: true }}>
              {t(`osm-builder-tip-${category}`)}
            </Markdown>
          </div>
        </section>
      </section>
      <section className={styles.builder}>
        <form className={styles.form}>
          <h3>{t(`osm-builder-title-configs`)}</h3>
          {builderOptionByType[category].options.map((option, idx) => {
            const htmlId = `ip_${option.id.replaceAll('-', '_')}`;

            if (option.condition) {
              const conditions = Array.isArray(option.condition[0])
                ? (option.condition as Condition[])
                : [option.condition as Condition];
              const finalState = conditions.reduce<string>(
                (prevState, condition) => {
                  if (prevState === 'not_meet') {
                    return prevState;
                  }

                  const lhs = options[category][condition[0]] as unknown as
                    | string
                    | number;
                  const op = condition[1];
                  const rhs = condition[2];

                  let state = 'unknown';

                  switch (op) {
                    case 'eq':
                      state = lhs === rhs ? 'meet' : 'not_meet';
                      break;
                    case 'is':
                      switch (rhs) {
                        case 'truthy':
                          state = !!lhs ? 'meet' : 'not_meet';
                          break;
                        case 'falsy':
                          state = !lhs ? 'meet' : 'not_meet';
                          break;
                      }
                  }

                  return state;
                },
                'unknown'
              );

              if (finalState === 'not_meet') {
                return null;
              }
            }

            switch (option.type) {
              case 'text':
                return (
                  <fieldset key={idx}>
                    <label htmlFor={htmlId}>
                      {t(`osm-builder-label-${option.id}`)}
                    </label>
                    <input
                      id={htmlId}
                      type="text"
                      value={options[category][option.id] as string}
                      placeholder={option.placeholder || ''}
                      onChange={(event) => {
                        setOptions({
                          ...options,
                          [category]: {
                            ...options[category],
                            [option.id]: event.currentTarget.value,
                          },
                        });
                      }}
                    />
                  </fieldset>
                );

              case 'number':
                return (
                  <fieldset key={idx}>
                    <label htmlFor={htmlId}>
                      {t(`osm-builder-label-${option.id}`)}
                    </label>
                    <input
                      id={htmlId}
                      type="number"
                      value={options[category][option.id] as string}
                      placeholder={option.placeholder}
                      maxLength={8}
                      {...(option.max && { max: option.max })}
                      {...(option.min && { min: option.min })}
                      {...(option.step && { step: option.step })}
                      onChange={(event) => {
                        setOptions({
                          ...options,
                          [category]: {
                            ...options[category],
                            [option.id]: event.currentTarget.value,
                          },
                        });
                      }}
                    />
                  </fieldset>
                );

              case 'date':
                return (
                  <fieldset key={idx}>
                    <label htmlFor={htmlId}>
                      {t(`osm-builder-label-${option.id}`)}
                    </label>
                    <input
                      id={htmlId}
                      type="date"
                      value={options[category][option.id] as string}
                      placeholder={option.placeholder}
                      onChange={(event) => {
                        setOptions({
                          ...options,
                          [category]: {
                            ...options[category],
                            [option.id]: event.currentTarget.value,
                          },
                        });
                      }}
                    />
                  </fieldset>
                );

              case 'select':
                return (
                  <fieldset key={idx}>
                    <label htmlFor={htmlId}>
                      {t(`osm-builder-label-${option.id}`)}
                    </label>
                    <div className="select-box">
                      <select
                        id={htmlId}
                        value={options[category][option.id] as string}
                        onChange={(event) => {
                          setOptions({
                            ...options,
                            [category]: {
                              ...options[category],
                              [option.id]: event.currentTarget.value,
                            },
                          });
                        }}
                      >
                        {(option as SelectBuilderOption).options.map(
                          (selectOption, idx2) => {
                            return (
                              <option key={idx2} value={selectOption.value}>
                                {t(
                                  `osm-builder-${option.id}-${selectOption.value}`
                                )}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>
                  </fieldset>
                );

              default:
                return null;
            }
          })}
        </form>
        <section className={styles.output}>
          <h3>{t(`osm-builder-title-preview`)}</h3>
          <div className={cx(styles.preview, category)}>
            {!isSSR && <PreviewBlock />}
          </div>
          <h3>{t(`osm-builder-title-code-example`)}</h3>
          <h4>In &lt;head /&gt;</h4>
          <CodeBox
            language="html"
            showLineNumbers={false}
            copyText={raw(`./examples/script.copy.html`)}
            text={raw(`./examples/script.codebox.html`)}
          />
          <h4>In &lt;body /&gt;</h4>
          <CodeBox
            language="html"
            showLineNumbers={false}
            copyText={getPrettifiedDOMSting(
              <Preview options={options} category={category} forCodeBox />
            )}
            text={getPrettifiedDOMSting(
              <body>
                <Preview options={options} category={category} forCodeBox />
              </body>
            )}
          />
        </section>
      </section>
    </main>
  );
};

export default Builder;
