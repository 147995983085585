import { ReactElement, isValidElement } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { format } from 'prettier';
import parserBabel from 'prettier/parser-babel';
import parserHTML from 'prettier/parser-html';

const getDOMType = (dom: ReactElement | HTMLElement | string) => {
  if (dom instanceof HTMLElement) {
    return dom.outerHTML;
  }

  if (isValidElement(dom)) {
    return renderToStaticMarkup(dom);
  }

  return dom;
};

export const getPrettifiedDOMSting = (
  dom: ReactElement | HTMLElement | string
): string => {
  if (typeof window === 'undefined') {
    return '';
  }

  return format(getDOMType(dom), {
    parser: 'babel',
    plugins: [parserBabel, parserHTML],
  }).replace(/[\r\n;]+$/, '');
};
