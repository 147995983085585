type BuilderSelectOption = {
  title: string;
  value: string;
};

export type Condition = [
  optionField: string,
  operator: 'eq' | 'gt' | 'lt' | 'is',
  value: string | number | boolean
];

type BuilderOption = {
  id: string;
  title: string;
  type: 'select' | 'text' | 'number' | 'date' | 'checkbox';
  options?: BuilderSelectOption[];
  placeholder?: string;
  defaultValue?: string | number | boolean;
  condition?: Condition | Condition[];
  max?: number;
  min?: number;
  step?: number;
  validator?: (value: string) => boolean;
};

export type SelectBuilderOption = {
  id: string;
  title: string;
  type: 'select' | 'text' | 'number';
  options: BuilderSelectOption[];
  condition?: Condition;
};

type BuilderOptionByType = {
  [key: string]: {
    id: string;
    title: string;
    options: BuilderOption[];
  };
};

export type DefaultBuilderOptionByType = {
  [key: string]: DefaultBuilderOption;
};

type DefaultBuilderOption = {
  [key: string]: string | number | boolean | undefined;
};

export const MIN_PRICE_AMOUNT = 150;
export const MAX_PRICE_AMOUNT = 350000;

const DEFAULT_PRICE_AMOUNT = 15400;

export const builderTypes = [
  'product',
  'strip',
  'banner',
  'payment-method',
  'coupon',
  'corner',
  'hosted-faq',
];

const validators = {
  required: (value: any) => {
    if (value === undefined) {
      return false;
    }

    if (value.toString() === '') {
      return false;
    }

    return true;
  },
  link: (value: string) => {
    return value
      ? value.indexOf('http://') === 0 ||
          value.indexOf('https://') === 0 ||
          value.indexOf('/') === 0
      : false;
  },
};

export const builderOptionByType: BuilderOptionByType = {
  product: {
    id: 'product',
    title: 'Product',
    options: [
      {
        id: 'type',
        title: 'Type',
        type: 'select',
        options: [
          { title: 'Block A', value: 'block' },
          { title: 'Block B', value: 'block-b' },
          { title: 'Inline A', value: 'inline' },
          { title: 'Inline B', value: 'inline-b' },
        ],
      },
      {
        id: 'theme',
        title: 'Theme',
        type: 'select',
        options: [
          { title: 'White', value: 'white' },
          { title: 'Dark', value: 'dark' },
          { title: 'Light', value: 'light' },
        ],
        condition: ['type', 'eq', 'block'],
      },
      {
        id: 'logo-theme',
        title: 'Logo Theme',
        type: 'select',
        options: [
          { title: 'Color', value: 'color' },
          { title: 'Solid', value: 'solid' },
          { title: 'Line', value: 'line' },
        ],
        condition: ['type', 'eq', 'block'],
      },
      {
        id: 'amount',
        title: 'Amount',
        type: 'number',
        min: MIN_PRICE_AMOUNT,
        step: 50,
        defaultValue: DEFAULT_PRICE_AMOUNT,
      },
      {
        id: 'quantity',
        title: 'Quantity',
        type: 'number',
        min: 1,
        max: 100,
        step: 1,
        defaultValue: 1,
      },
      {
        id: 'max-amount',
        title: 'Max Amount',
        type: 'number',
        min: MIN_PRICE_AMOUNT,
        step: 50,
        defaultValue: MAX_PRICE_AMOUNT,
      },
      {
        id: 'max-width',
        title: 'Max Width',
        type: 'number',
        min: 0,
        step: 5,
        defaultValue: 420,
      },
      {
        id: 'shopify-page',
        title: 'Shopify Page',
        type: 'select',
        options: [
          { title: '-', value: '' },
          { title: 'Product', value: 'product' },
          { title: 'Cart', value: 'cart' },
        ],
      },
      {
        id: 'coupon',
        title: 'Coupon',
        type: 'text',
        placeholder: 'WINTERSALE2022',
        defaultValue: 'WINTERSALE2022',
      },
      {
        id: 'coupon-discount-type',
        title: 'Discount Type',
        type: 'select',
        defaultValue: 'amount',
        options: [
          { title: 'Amount', value: 'amount' },
          { title: 'percent', value: 'percent' },
        ],
        condition: ['coupon', 'is', 'truthy'],
      },
      {
        id: 'coupon-discount-amount',
        title: 'Discount Amount',
        type: 'number',
        step: 20,
        defaultValue: 100,
        condition: [
          ['coupon', 'is', 'truthy'],
          ['coupon-discount-type', 'eq', 'amount'],
        ],
      },
      {
        id: 'coupon-discount-percent',
        title: 'Discount Percent',
        type: 'number',
        min: 0,
        max: 100,
        step: 1,
        defaultValue: 5,
        condition: [
          ['coupon', 'is', 'truthy'],
          ['coupon-discount-type', 'eq', 'percent'],
        ],
      },
      {
        id: 'coupon-expires-at',
        title: 'Expires At',
        type: 'date',
        condition: ['coupon', 'is', 'truthy'],
      },
      {
        id: 'coupon-max-count',
        title: 'Max Count',
        type: 'number',
        condition: ['coupon', 'is', 'truthy'],
      },
      {
        id: 'coupon-minimum-amount',
        title: 'Minimum Amount',
        type: 'number',
        step: 500,
        defaultValue: undefined,
        condition: [['coupon', 'is', 'truthy']],
      },
    ],
  },

  strip: {
    id: 'strip',
    title: 'Strip',
    options: [
      {
        id: 'theme',
        title: 'Theme',
        type: 'select',
        options: [
          { title: 'White', value: 'white' },
          { title: 'Dark', value: 'dark' },
          { title: 'Light Blue', value: 'light-blue' },
        ],
      },
      {
        id: 'link',
        title: 'Link',
        type: 'text',
        placeholder: 'https://smartpay.co',
        validator: validators.link,
      },
    ],
  },

  banner: {
    id: 'banner',
    title: 'Banner',
    options: [
      {
        id: 'theme',
        title: 'Theme',
        type: 'select',
        options: [
          { title: 'White', value: 'white' },
          { title: 'Dark', value: 'dark' },
          { title: 'Dark Gradient', value: 'dark-gradient' },
        ],
      },
      {
        id: 'link',
        title: 'Link',
        type: 'text',
        placeholder: 'https://smartpay.co',
        validator: validators.link,
      },
    ],
  },

  'payment-method': {
    id: 'payment-method',
    title: 'Payment Method',
    options: [
      {
        id: 'theme',
        title: 'Theme',
        type: 'select',
        options: [
          { title: 'White', value: 'white' },
          { title: 'Transparent', value: 'transparent' },
        ],
      },
      {
        id: 'logo-theme',
        title: 'Logo Theme',
        type: 'select',
        options: [
          { title: 'Color', value: 'color' },
          { title: 'Solid', value: 'solid' },
          { title: 'Line', value: 'line' },
        ],
      },
      {
        id: 'amount',
        title: 'Amount',
        type: 'number',
        min: MIN_PRICE_AMOUNT,
        step: 50,
        defaultValue: DEFAULT_PRICE_AMOUNT,
      },
      {
        id: 'max-amount',
        title: 'Max Amount',
        type: 'number',
        min: MIN_PRICE_AMOUNT,
        step: 50,
        defaultValue: MAX_PRICE_AMOUNT,
      },
      {
        id: 'quantity',
        title: 'Quantity',
        type: 'number',
        min: 1,
        max: 100,
        step: 1,
        defaultValue: 1,
      },
      {
        id: 'max-width',
        title: 'Max Width',
        type: 'number',
        min: 0,
        step: 5,
        defaultValue: 600,
      },
      {
        id: 'shopify-page',
        title: 'Shopify Page',
        type: 'select',
        options: [
          { title: '-', value: '' },
          { title: 'Product', value: 'product' },
          { title: 'Cart', value: 'cart' },
        ],
      },
      {
        id: 'coupon',
        title: 'Coupon',
        type: 'text',
        placeholder: 'WINTERSALE2022',
        defaultValue: 'WINTERSALE2022',
      },
      {
        id: 'coupon-discount-type',
        title: 'Discount Type',
        type: 'select',
        defaultValue: 'amount',
        options: [
          { title: 'Amount', value: 'amount' },
          { title: 'percent', value: 'percent' },
        ],
        condition: ['coupon', 'is', 'truthy'],
      },
      {
        id: 'coupon-discount-amount',
        title: 'Discount Amount',
        type: 'number',
        step: 20,
        defaultValue: 100,
        condition: [
          ['coupon', 'is', 'truthy'],
          ['coupon-discount-type', 'eq', 'amount'],
        ],
      },
      {
        id: 'coupon-discount-percent',
        title: 'Discount Percent',
        type: 'number',
        min: 0,
        max: 100,
        step: 1,
        defaultValue: 5,
        condition: [
          ['coupon', 'is', 'truthy'],
          ['coupon-discount-type', 'eq', 'percent'],
        ],
      },
      {
        id: 'coupon-expires-at',
        title: 'Expires At',
        type: 'date',
        condition: ['coupon', 'is', 'truthy'],
      },
      {
        id: 'coupon-max-count',
        title: 'Max Count',
        type: 'number',
        condition: ['coupon', 'is', 'truthy'],
      },
      {
        id: 'coupon-minimum-amount',
        title: 'Minimum Amount',
        type: 'number',
        step: 50,
        defaultValue: 10000,
        condition: [['coupon', 'is', 'truthy']],
      },
    ],
  },

  coupon: {
    id: 'coupon',
    title: 'coupon',
    options: [
      {
        id: 'type',
        title: 'Type',
        type: 'select',
        options: [
          { title: 'Block', value: 'block' },
          { title: 'Horizontal', value: 'horizontal' },
        ],
      },
      {
        id: 'theme',
        title: 'Theme',
        type: 'select',
        options: [
          { title: 'Light', value: 'light' },
          { title: 'Light Blue', value: 'light-blue' },
          { title: 'Dark', value: 'dark' },
        ],
      },
      {
        id: 'coupon',
        title: 'Coupon',
        type: 'text',
        placeholder: 'WINTERSALE2022',
        defaultValue: 'WINTERSALE2022',
        validator: validators.required,
      },
      {
        id: 'discount-type',
        title: 'Discount Type',
        type: 'select',
        defaultValue: 'amount',
        options: [
          { title: 'Amount', value: 'amount' },
          { title: 'percent', value: 'percent' },
        ],
      },
      {
        id: 'discount-amount',
        title: 'Discount Amount',
        type: 'number',
        step: 20,
        defaultValue: 100,
        condition: ['discount-type', 'eq', 'amount'],
      },
      {
        id: 'discount-percent',
        title: 'Discount Percent',
        type: 'number',
        min: 0,
        max: 100,
        step: 1,
        defaultValue: 5,
        condition: ['discount-type', 'eq', 'percent'],
      },
      {
        id: 'expires-at',
        title: 'Expires At',
        type: 'date',
      },
      {
        id: 'max-count',
        title: 'Max Count',
        type: 'number',
      },
      {
        id: 'minimum-amount',
        title: 'Minimum Amount',
        type: 'number',
        step: 50,
        defaultValue: 10000,
      },
    ],
  },

  corner: {
    id: 'corner',
    title: 'Corner',
    options: [
      {
        id: 'theme',
        title: 'Theme',
        type: 'select',
        options: [
          { title: 'Light', value: 'light' },
          { title: 'Dark', value: 'dark' },
        ],
      },
      {
        id: 'position',
        title: 'Position',
        type: 'select',
        options: [
          { title: 'Right', value: 'right' },
          { title: 'Left', value: 'left' },
        ],
      },
      {
        id: 'with-button',
        title: 'With Button',
        type: 'select',
        options: [
          { title: 'Yes', value: '1' },
          { title: 'No', value: '' },
        ],
      },
      {
        id: 'coupon',
        title: 'Coupon',
        type: 'text',
        placeholder: 'WINTERSALE2022',
        defaultValue: 'WINTERSALE2022',
      },
      {
        id: 'coupon-discount-type',
        title: 'Discount Type',
        type: 'select',
        defaultValue: 'amount',
        options: [
          { title: 'Amount', value: 'amount' },
          { title: 'percent', value: 'percent' },
        ],
        condition: ['coupon', 'is', 'truthy'],
      },
      {
        id: 'coupon-discount-amount',
        title: 'Discount Amount',
        type: 'number',
        step: 20,
        defaultValue: 100,
        condition: [
          ['coupon', 'is', 'truthy'],
          ['coupon-discount-type', 'eq', 'amount'],
        ],
      },
      {
        id: 'coupon-discount-percent',
        title: 'Discount Percent',
        type: 'number',
        min: 0,
        max: 100,
        step: 1,
        defaultValue: 5,
        condition: [
          ['coupon', 'is', 'truthy'],
          ['coupon-discount-type', 'eq', 'percent'],
        ],
      },
      {
        id: 'coupon-minimum-amount',
        title: 'Minimum Amount',
        type: 'number',
        step: 50,
        defaultValue: undefined,
        condition: [['coupon', 'is', 'truthy']],
      },
    ],
  },

  'hosted-faq': {
    id: 'hosted-faq',
    title: 'hosted-faq',
    options: [
      {
        id: 'brand-name',
        title: 'Brand Name',
        type: 'text',
        placeholder: 'Nike',
        defaultValue: 'Nike',
      },
    ],
  },
};

const optionDefaultValue = (option: BuilderOption) => {
  switch (option.type) {
    case 'number':
      return option.defaultValue
        ? option.defaultValue
        : option.id === 'amount'
        ? DEFAULT_PRICE_AMOUNT
        : undefined;

    case 'text':
      return option.defaultValue || '';

    case 'select':
      return (option as SelectBuilderOption).options[0].value;

    case 'date':
      return option.defaultValue || '';

    case 'checkbox':
      return option.defaultValue || false;
  }
};

export const defaultBuilderOptions =
  builderTypes.reduce<DefaultBuilderOptionByType>((accum, osmType) => {
    accum[osmType] = builderOptionByType[
      osmType
    ].options.reduce<DefaultBuilderOption>((optionFields, option) => {
      optionFields[option.id] = optionDefaultValue(option);

      return optionFields;
    }, {});

    return accum;
  }, {});
