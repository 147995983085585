import { graphql } from 'gatsby';

import OSMLayout from '../../components/OSMScreen/OSMLayout';

export const osmIndexQuery = graphql`
  query OSMIndexQuery($language: String!) {
    site {
      siteMetadata {
        name
        tagline
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const OSMIndex = () => {
  return <OSMLayout />;
};

export default OSMIndex;
